<template>
	<main class="main" id="main" v-bind:class="theme">
		<video width="320" height="240" muted autoplay loop>
			<source v-bind:src="video" type="video/mp4" />
			Your browser does not support the video tag.
		</video>

		<div class="container">
			<div class="intro">
				<div class="mycelium">
					<img v-bind:src="mycelium" alt="the mycelium" title="The Mycelium" />
				</div>
				<!-- end mycelium -->
				<h1>Create, manage and grow meaningful content</h1>
			</div>
			<!-- end intro -->
			<div class="login">
				<div class="login-form">
					<h2>Login</h2>
					<LoginForm></LoginForm>
				</div>
				<!-- end login-form -->
			</div>
			<!-- end login -->
		</div>
		<!-- end container -->
	</main>
</template>

<script>
import LoginForm from "./../components/forms/LoginForm.vue"
import axios from "axios"
export default {
	data() {
		return {
			logo: require("../assets/img/r-logo-white.svg"),
			mycelium: require("../assets/img/mycelium-lrg.svg"),
			video: require("../assets/video/home.mp4"),
			theme: "black-bg page-login",
		}
	},
	setup() {},
	created() {},
	methods: {
		async getData() {
			try {
				const response = await axios.get("api/user/login")
				// JSON responses are automatically parsed.
				console.log(response)
			} catch (error) {
				console.log(error)
			}
		},
	},
	components: {
		LoginForm,
	},
}
</script>

<style lang="scss">
@import "./src/assets/scss/views/login.scss";
</style>
